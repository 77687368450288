import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FlightUtils } from "../common/FlightUtils";
import {
  addPercentageToPrice,
  formatPrice,
  getFormattedTime,
  getItemFromLocalStorage,
} from "../common/methods";
import { useFlights } from "../hooks/useFlights";
import { useStaticImages } from "../hooks/useStaticImages";
import { useStoreFly } from "../store";
import { AIRLINES_IMAGE_ENDPOINT } from "../utils/constants";
import {
  CURRENCY_SIGN,
  SELECTED_CABINS,
  SORT_BY,
  SORT_TO_STORE_PATH,
} from "../utils/enums";
import { AccordionDetails } from "./common/AccordionDetails";
import { Loading } from "./common/Loading";
import { NoResultsFound } from "./common/NoResultsFound";
import { CheckboxWithImage } from "./common/RadioButtonWithImage";
import { Filters } from "./filters/filters";
import {
  formatDateToDayMonth,
  formatDateToDayMonthAndMonthNumber,
} from "./common/TimeDateAndStops";
import { isMobile } from "react-device-detect";
import { Tooltip } from "@mui/material";
import { Chip } from "@mui/material";

const FlightUtil = new FlightUtils();

export const SortBanner = ({ translation }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("quality");
  const carriers = useStoreFly((state) => state.airlines);
  const loadingData = useStoreFly((state) => state.loadingData); 
  const updateAncillary = useStoreFly((state) => state.updateAncillary); 
  const updateBaggInfo = useStoreFly((state) => state.updateBaggInfo);  
  const mainListRef = useRef(null);

  useEffect(() => {
    mainListRef.current.scrollIntoView();
  }, []);

  return (
    <section class="content" style={{ padding: isMobile ? "10px 0px" : "95px 0px", marginTop: isMobile ? "30px" : ""}}>
      <div class="container">
        <div class="row">
          <Filters translation={translation} />
          <div
            class="col-lg-7 mt-lg-0 mt-md-0 mt-4 valuetabs col-md-8 col-12"
            ref={mainListRef}
          >
            <SortingTabs
              carriers={carriers}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              loadingData={loadingData}
              translation={translation}
            />
            <TabContent
              activeTab={activeTab}
              loadingData={loadingData}
              navigate={navigate}
              translation={translation}
            />
          </div>
          <div class="col-lg-2 mt-3 mt-lg-0 mt-md-4  col-md-6 ml-auto col-12 ">
            <div class="col-lg-12 logobox text-center bg-white col-md-12 col-12 sticky">
              <img
                src={require("../assets/img/sims_marketing_agency_logo.png")}
                alt="agency"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const SortingTabs = ({
  carriers,
  setActiveTab,
  activeTab,
  loadingData,
  translation,
}) => {
  
  const filter = getItemFromLocalStorage("filter");
  const { priceFlights, durationFlights, qualityFlights } = useFlights();
  const durationFirstFlight = durationFlights?.sort((a, b) => a.totalJourney - b.totalJourney)?.[0];
  const priceFirstFlight = priceFlights?.sort((a, b) => a.totalPrice - b.totalPrice)?.[0];
  const qualityFirstFlight = qualityFlights?.sort((a, b) => (a.totalPrice+a.totalJourney) -(b.totalPrice+b.totalJourney))?.[0]; 

  const durationResponse = FlightUtil.flightDetailsByFlight(
    durationFirstFlight,
    carriers
  );

  const priceResponse = FlightUtil.flightDetailsByFlight(
    priceFirstFlight,
    carriers
  );

  const qualityResponse = FlightUtil.flightDetailsByFlight(
    qualityFirstFlight,
    carriers
  );

  const qualityPriceAndTime = qualityFirstFlight ? (
    <span>
      {addPercentageToPrice(qualityFirstFlight?.totalPrice) } {CURRENCY_SIGN[filter?.curr || "EUR"]} -{" "}
      {qualityResponse?.totalOfObIbHoursAndMinutes?.hours}h{" "}
      {qualityResponse?.totalOfObIbHoursAndMinutes?.minutes}m
    </span>
  ) : (
    <span>... ...</span>
  );

  const pricePriceAndTime = priceFirstFlight ? (
    <span>
      {addPercentageToPrice(priceFirstFlight?.totalPrice)} {CURRENCY_SIGN[filter?.curr || "EUR"]} -
      {priceResponse?.totalOfObIbHoursAndMinutes?.hours}h{" "}
      {priceResponse?.totalOfObIbHoursAndMinutes?.minutes}m
    </span>
  ) : (
    <span>... ...</span>
  );

  const durationPriceAndTime = durationResponse ? (
    <span>
      {addPercentageToPrice(durationFirstFlight?.totalPrice)} {CURRENCY_SIGN[filter?.curr || "EUR"]} -{" "}
      {durationResponse?.totalOfObIbHoursAndMinutes?.hours}h{" "}
      {durationResponse?.totalOfObIbHoursAndMinutes?.minutes}m
    </span>
  ) : (
    <span>... ...</span>
  );

  return (
    <ul class="nav nav-tabs" style={{ justifyContent: "center" }}>
      <li>
        <a
          data-toggle="tab"
          // href="#tab-value1"
          class={activeTab === "quality" && "active"}
          onClick={() => {
            setActiveTab("quality")
            localStorage.setItem("sort", "quality")
          }}
        >
          {translation("best")}
          <br />
          {loadingData ? <span>Loading...</span> : qualityPriceAndTime}
        </a>
      </li>
      <li>
        <a
          data-toggle="tab"
          // href="#tab-value2"
          class={activeTab === "price" && "active"}
          // onClick={() => setActiveTab("price")}
          onClick={() => {
            setActiveTab("price")
            localStorage.setItem("sort", "price")
          } }
        >
          {translation("best_value")}
          <br />
          {loadingData ? <span>Loading...</span> : pricePriceAndTime}
        </a>
      </li>
      <li>
        <a
          data-toggle="tab"
          // href="#tab-value3"
          class={activeTab === "duration" && "active"}
          // onClick={() => setActiveTab("duration")}
          onClick={() => {
            setActiveTab("duration")
            localStorage.setItem("sort", "duration")
          } }
        >
          {translation("fastest")}
          <br />
          {loadingData ? <span>Loading...</span> : durationPriceAndTime}
        </a>
      </li>
    </ul>
  );
};

export const TabContent = ({
  activeTab,
  loadingData,
  navigate,
  translation,
}) => {
  const [tabChanged, setTabChanged] = useState("quality");
  const [searchParams] = useSearchParams();

  const search = localStorage.getItem("search");
  const filter = getItemFromLocalStorage("filter");
  const {
    flyFrom,
    flyTo,
    dateFrom,
    dateTo,
    curr,
    adults,
    children,
    babies,
    cityNameFrom,
    cityNameTo,
    returnOrOneWay,
    originCode,
    destinationCode,
    returnFromDate
  } =
    typeof localStorage.getItem("search") === "string"
      ? JSON.parse(search)
      : {};

  const { priceFlights, durationFlights, qualityFlights } = useFlights();
   

  const filterRoute = useStoreFly((state) => state.filterRoute);
  const currency = useStoreFly((state) => state.currency);
  const fromDate = getFormattedTime(filterRoute?.dateFrom);
  const toDate = getFormattedTime(filterRoute?.dateFrom);
  const activeClass = "tab-pane fade show active";
  const notActiveClass = "tab-pane fade in";


  const QualityFlightsComponent = qualityFlights?.length ? (
      <FlightTicket
        data={qualityFlights || []}
        sort={activeTab}
        currency={filter?.curr || curr || "EUR"}
        navigate={navigate}
        translation={translation}
      />
  ) : (
    <NoResultsFound />
  );
  const DurationFlightsComponent = durationFlights?.length ? (
    <FlightTicket
      data={durationFlights || []}
      sort={activeTab}
      currency={filter?.curr || curr || "EUR"}
      navigate={navigate}
      translation={translation}
    />
  ) : (
    <NoResultsFound />
  );
  const PriceFlightsComponent = priceFlights?.length ? (
    <FlightTicket
      data={priceFlights || []}
      sort={activeTab}
      currency={filter?.curr || curr || "EUR"}
      navigate={navigate}
      translation={translation}
    />
  ) : (
    <NoResultsFound />
  );


  return (
    <div class="tab-content">
      <div
        id="tab-value1"
        class={`${
          activeTab === SORT_BY.QUALITY ? activeClass : notActiveClass
        }`}
        
        
      >
        <div class="row ml-auto mr-auto"
          style={{position: "relative", zIndex: 1}} 
        >
          {loadingData ? (
            <Loading
              fromCode={originCode}
              toCode={destinationCode}
              from={cityNameFrom}
              to={cityNameTo}
              fromDate={dateFrom}
              toDate={returnFromDate}
              loadingData={loadingData}
            />
          ) : QualityFlightsComponent}
        </div>
      </div>
      <div
        id="tab-value2"
        class={`${activeTab === SORT_BY.PRICE ? activeClass : notActiveClass}`}
      >
        <div class="row ml-auto mr-auto" style={{position: "relative", zIndex: 1}} >
          {loadingData ? (
            <Loading
              fromCode={originCode}
              toCode={destinationCode}
              from={cityNameFrom}
              to={cityNameTo}
              fromDate={dateFrom}
              toDate={returnFromDate}
              loadingData={loadingData}
            />
          ) : (
            PriceFlightsComponent
          )}
        </div>
      </div>
      <div
        id="tab-value3"
        class={`${
          activeTab === SORT_BY.DURATION ? activeClass : notActiveClass
        }`}
      >
        <div class="row ml-auto mr-auto" style={{position: "relative", zIndex: 1}} >
          {loadingData ? (
            <Loading
              fromCode={originCode}
              toCode={destinationCode}
              from={cityNameFrom}
              to={cityNameTo}
              fromDate={dateFrom}
              toDate={returnFromDate}
              loadingData={loadingData}
            />
          ) : (
            DurationFlightsComponent
          )}
        </div>
      </div>
    </div>
  );
};

export const FlightTicket = ({
  data,
  sort,
  currency,
  navigate,
  translation,
}) => { 
 
  const baggage = useStoreFly((state) => state.baggage);
  const carriers = useStoreFly((state) => state.airlines);
  const setCurrentFlightBooking = useStoreFly(
    (state) => state.setCurrentFlightBooking
  );
  const setSelectedFlightDetailsExpand = useStoreFly(
    (state) => state.setSelectedFlightDetailsExpand
  );
  const {
    arrowDown,
    luggage,
    suitcase,
    info,
    selfCare,
    // notification,
    disabledLuggage,
    disabledSuitCase,
  } = useStaticImages();
  const path = SORT_TO_STORE_PATH[sort];

  const sortedFlightItem = JSON.parse(localStorage.getItem(path) || "{}") 
  // || useStoreFly((state) => state[path]);
  const setAirportNamesByFlightId = useStoreFly(
    (state) => state.setAirportNamesByFlightId
  );
   
  const lowestPrice = data?.reduce((minPrice, product) => {
    return product.totalPrice < minPrice
      ? product.totalPrice
      : minPrice;
  }, data[0]?.totalPrice);
  let baggageForcalc = useStoreFly((state) => state.baggage);
 
  let  sortData // sort !== "duration"?  data=data?.sort((a, b) => a.price - b.price) : data;
  if(sort==='duration'){sortData=data?.sort((a, b) => a.totalJourney - b.totalJourney)}
  if(sort==='price'){sortData=data?.sort((a, b) => a.totalPrice - b.totalPrice)}
  if(sort==='quality'){sortData=data?.sort((a, b) => (a.quality+a.totalPrice) -  (b.quality+b.totalPrice) )}
  return (
    <Fragment>
      {sortData?.map((flight, i) => {
        const { inboundFlights, outboundFlights } =
        FlightUtil.getInboundAndOutboundRoutes(flight); 
        const airlineOutbound = FlightUtil.getAirline(
          outboundFlights?.[0]?.airline,
          carriers
        );
        const airlineInbound = FlightUtil.getAirline(
          inboundFlights?.[0]?.airline,
          carriers
        );
        
        // const handBaggageInKg = `${(flight?.baggageMap.ADT[0]?.carryOnWeight)||0}kg`;
        const handBaggageInKg = translation('bagg_info');
         
         
        // const holdBaggageInKg = `${(flight?.baggageMap.ADT[0]?.baggageWeight)||0}kg`;
        const holdBaggageInKg = '';
        const holdBaggageIsPayed =  false;
        const hasSelfTransfer =false;
        const flightClassCategory = translation(
          SELECTED_CABINS[flight?.journeys?.journey_0?.segmengtIds[0].cabinClass]
        );
        const isVirtualInterlining = false;
        const numberOfSeats = 9;
        const price = formatPrice(addPercentageToPrice(flight?.totalPrice));
        const priceDisc = formatPrice(addPercentageToPrice(flight?.totalPrice+20));
        const [firstPrice, secondPrice] = price?.toString()?.split();
        const [firstPriceDisc, secondPriceDisc] = priceDisc?.toString()?.split();
        const {
          stops: obStops,
          departureTime: obDepartureTime,
          arrivalTime: obArrivalTime,
          hours: obHours,
          minutes: obMinutes,
          cityCodeFrom: obCityCodeFrom,
          cityCodeTo: obCityCodeTo,
          startingFlight: obStartingFlight,
          // endingFlight: obEndingFlight,
        } = FlightUtil.flightDetails(outboundFlights, flight, "outbound");

        const {
          stops: ibStops,
          departureTime: ibDepartureTime,
          arrivalTime: ibArrivalTime,
          hours: ibHours,
          minutes: ibMinutes,
          cityCodeFrom: ibCityCodeFrom,
          cityCodeTo: ibCityCodeTo,
          startingFlight: ibStartingFlight,
          // endingFlight: ibEndingFlight,
        } = FlightUtil.flightDetails(inboundFlights, flight, "inbound"); 
        const isOnlyOneWay = inboundFlights?.length === 0;

      
        const isDirectDepartRoute = flight?.journeys?.journey_0.segmengtIds.length==1;
        const isDirectReturnRoute = flight?.journeys?.journey_1?.segmengtIds?.length==1;

      
        const classWithMargin =
          i > 0
            ? "col-lg-12 whitebox mt-4 col-md-12 col-12"
            : "col-lg-12 whitebox col-md-12 col-12 ";
        const mobileAirlineDimensions = window.innerWidth < 801 ? "32x32" : "32x32";    
        return (
          <div
            class={`${classWithMargin} ${
              lowestPrice === flight?.price && i === 0  ? "flight_ticket" : null
            }`}
            key={flight?.solutionId}
            data-cheapest={
              lowestPrice === flight?.price && i === 0 ? translation("cheapest") : null
            }
          >
            <div
              class="row pt-3"
            >
              <div class="col-lg-9 col-md-12 col-12">
                <div class="row">
                  <div class="col-lg-5 col-md-12 col-12">
                    {true ? (
                      <label
                        class="carrier-label"
                        for={`${flight?.solutionId}_"outbound"`}
                      >
                        <img
                          srcSet={`${AIRLINES_IMAGE_ENDPOINT}/${mobileAirlineDimensions}/${airlineOutbound?.id}.png`}
                          alt={airlineOutbound?.name}
                        />
                        {airlineOutbound?.name}
                      </label>
                    ) : (
                      <div class="custom-control custom-radio normal-radio">
                        <CheckboxWithImage
                          id={flight?.solutionId}
                          flight={flight}
                          airline={airlineOutbound}
                          startingFlight={obStartingFlight}
                          selectedValue={flight?.selectedFlightType}
                          type="outbound"
                          handleChange={() =>
                            setSelectedFlightDetailsExpand(
                              sortedFlightItem,
                              flight?.solutionId,
                              "outbound",
                              outboundFlights,
                              path
                            )
                          }
                          isOneWay={isOnlyOneWay}
                        />
                      </div>
                    )}
                  </div>
                  <div class="col-lg-7 tinfo col-md-12 col-12">
                    <ul>
                      <li class="text text-right">
                        {obDepartureTime}
                        <br />
                        {obCityCodeFrom}
                      </li>
                      <li class="mid text-center">
                        {obHours}h {obMinutes}m
                        <hr />
                        {isDirectDepartRoute?translation("direct"):''}
                        {!isDirectDepartRoute && obStops}{" "}
                        {obStops > 1
                          ? !isDirectDepartRoute && translation("stops")?.toLowerCase()
                          : !isDirectDepartRoute && translation("stop")}
                      </li>
                      <li class="text">
                        {obArrivalTime}
                        <br />
                        {obCityCodeTo}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-5 col-md-12 col-12">
                    {false && (
                      <div class="custom-control custom-radio normal-radio">
                        <CheckboxWithImage
                          id={flight?.solutionId}
                          flight={flight}
                          startingFlight={ibStartingFlight}
                          airline={airlineInbound}
                          selectedValue={flight?.selectedFlightType}
                          type="inbound"
                          handleChange={() => {
                            setSelectedFlightDetailsExpand(
                              sortedFlightItem,
                              flight?.solutionId,
                              "inbound",
                              inboundFlights,
                              path
                            );
                          }}
                        />
                      </div>
                    )}
                    {!isOnlyOneWay && <label
                      class="carrier-label"
                      for={`${flight?.solutionId}_"inbound"`}
                    >
                      <img
                        srcSet={`${AIRLINES_IMAGE_ENDPOINT}/32x32/${airlineInbound?.id}.png`}
                        alt={airlineInbound?.name}
                      />
                      
                      {airlineInbound?.name}
                    </label>}
                  </div>
                  {!isOnlyOneWay && (
                    <div class="col-lg-7 tinfo col-md-12 col-12">
                      <ul>
                        <li class="text text-right">
                          {ibDepartureTime}
                          <br />
                          {ibCityCodeFrom}
                        </li>
                        <li class="mid text-center">
                          {ibHours}h {ibMinutes}m
                          <hr />
                          {isDirectReturnRoute?translation("direct"):''}
                          {!isDirectReturnRoute &&  ibStops}{" "}
                          {ibStops > 1
                            ? !isDirectReturnRoute && translation("stops")
                            : !isDirectReturnRoute && translation("stop")}
                        </li>
                        <li class="text">
                          {ibArrivalTime}
                          <br />
                          {ibCityCodeTo}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <div class="row pt-3">
                  <div class="col-lg-12 col-md-12 col-12">
                    <ul class="lugguge-info">
                      <li class="mid text-center"> 
                        {handBaggageInKg}<span>&gt;</span>
                      {/* {baggageForcalc ?  (<span style={{fontSize: "15px"}}>{baggageForcalc.cabin}</span>):""}
                        {!handBaggageInKg ? (
                          
                          <img srcSet={disabledSuitCase} alt="Dsuitcase" width={25} />
                        ) : (
                          <img src={suitcase} alt="suitcase" style={{ margin:0 }} />
                        )}
                        {handBaggageInKg || ""} */}
                      </li>
                      <li class="text text-right">
                      {/* {baggageForcalc ?  (<span style={{fontSize: "15px"}}>{baggageForcalc.checked}</span>):""}
                        {!holdBaggageInKg || !holdBaggageIsPayed ? (
                          <img srcSet={disabledLuggage} alt="Dluggage" width={25} />
                        ) : (
                          <img srcSet={luggage} alt="luggage" width={25}/>
                        )}
                        {holdBaggageInKg || ""} | {flightClassCategory || ""} */}
                      </li>
                      {hasSelfTransfer && (
                        <li class="text">
                          <img src={selfCare} alt="selfcare" width={25}/>{" "}
                          {translation("self_transfer")}
                        </li>
                      )}
                      {isVirtualInterlining && (
                        <Tooltip title="Click to see virtual interlining details page">
                        <a href="/virtual-interlining" target="_blank" >
                          <li class="text">
                          <img src={info} alt="info" width={25}/>{" "}
                          {translation("virtual_interlining")}
                        </li></a>
                        </Tooltip>
                       
                      )}
                    </ul>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-3 rinfo text-center col-md-12 col-12">
              

                {/* {i === 0 && (
                  <img
                    class="img-noti"
                    srcSet={notification}
                    alt="notification"
                  />
                )} */}
                <span class="d-inline-block w-100 me-zbritje">
                  {i===0 &&  ( 
                    <span class="me-zbritje">
                      {firstPriceDisc} {secondPriceDisc && "."} <sup>{secondPriceDisc}</sup>
                      {CURRENCY_SIGN[currency]} 
                    </span> 
                  )}                  

                </span>
                <span class="d-inline-block price-text w-100">
                  {firstPrice} {secondPrice && "."} <sup>{secondPrice}</sup>
                  {CURRENCY_SIGN[currency]} 
                </span>
                {/* <span class="d-inline-block w-100" style={{ fontSize: "14px", fontWeight: "500"}}>
                  {numberOfSeats}{" "}
                  {numberOfSeats && translation("seats_left_at_this_price")}
                </span> */}
                <button
                  class="default-yellow-btn mt-3 select"
                  onClick={(e) => {
                    e.target.blur();
                    setCurrentFlightBooking({
                      ...flight,
                      bnum: baggage?.cabin + baggage?.checked,
                    });
                    localStorage.setItem(
                      "bnum",
                      JSON.stringify({
                        cabin: baggage?.cabin,
                        checked: baggage?.checked,
                        passengers:
                          flight?.adults + flight?.children + flight?.infants,
                      })
                    );
                    navigate(`/booking?flightId=${flight?.solutionId}`);
                  }}
                >
                  {translation("select")} &gt;
                </button>
              </div>
            </div>
            <div class="row bg-angles position-relative pt-3">
              <AccordionDetails
                arrowDown={arrowDown}
                id={flight?.solutionId}
                type={flight?.selectedFlightType}
                record={flight}
                flights={data}
                routes={
                  // flight?.selectedFlightType === "inbound"
                  //   ? inboundFlights
                  //   : outboundFlights
                  [...outboundFlights, ...inboundFlights]
                }
                setAirportNamesByFlightId={setAirportNamesByFlightId}
              />
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};
