import moment from "moment";
import {
  getFormattedTime,
  toHoursAndMinutes,
  tolocaleDate,
} from "../common/methods";

export const useTimeDifference = (flight) => {  
  const departureTime = getFormattedTime(flight?.departureDate); 
  const arrivalTime = getFormattedTime(flight?.arrivalDate);
  const localDep = tolocaleDate(flight?.departureDate);
  const localArr = tolocaleDate(flight?.arrivalDate);
  const dep = moment(flight?.departureDate);
  const arr = moment(flight?.arrivalDate);

  const diffInHours = moment.duration(arr.diff(dep));
  const { hours, minutes } = toHoursAndMinutes(
    diffInHours.asMinutes()?.toFixed()
  );

  const timeFormatted = `${hours}h ${minutes}m`; 

  return {
    departureTime, 
    arrivalTime,
    localDep,
    localArr,
    hours,
    minutes,
    timeFormatted,
  };
};
