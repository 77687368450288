const Footer = ({ style }) => {

    const handleResize = () => {
        console.log('reload')
        window.location.reload();
      };
    
      window.addEventListener('resize', handleResize);
   
    return (
        <section class="footer" style={style}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 logo col-md-4 col-12">
                        <a href="#"><img src={require('../assets/img/logo-footer.png')} /></a>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <h3>Contact</h3>
                        <p>
                            <a href="tel:383049253035">+383 (0) 49 25 30 35</a><br/>
                            <a href="mailto:info@fluturolire.com">info@fluturolire.com</a>
                        </p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <h3>Connect with Us</h3>
                        <a class="mr-2" target='_blank' href="https://www.facebook.com/Fluturolirecom"><img src={require('../assets/img/icon-facebook.png')} /></a>
                        <a target='_blank' href="https://www.instagram.com/fluturolirecom"><img src={require('../assets/img/icon-instagram.png')} /></a>
                    </div>
                   
                </div>
            </div>
        </section>
    )
}

export default Footer