import { Fragment, useEffect, useState } from "react";
import { FlightUtils } from "../../../common/FlightUtils";
import { TimeDateAndStops } from "../../../components/common/TimeDateAndStops";
import { useStaticImages } from "../../../hooks/useStaticImages";
import { useStoreFly } from "../../../store";
import CloseIcon from "@mui/icons-material/Close";
import Illustration from "@kiwicom/orbit-components/lib/Illustration";
import {
  AIRLINES_IMAGE_ENDPOINT,
  BOOKING_CHECK_FLIGHT,
  FLUTURO_LIRE_REDIRECT,
  GET_LIST_COUNTRY_ALL,
  ITRAVEL_KEY_API,
  MAIN_LAMBDA,
  MAIN_PASSENGER_INITIAL_FORM,
} from "../../../utils/constants";
import { CODE_TO_SEVERITY, CURRENCY_SIGN, PARTNERID, SIGNATURE } from "../../../utils/enums";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { GET, POST } from "../../../utils/api";
import {
  addPercentageToPrice,
  calculateTotalTime,
  formatPrice,
  getItemFromLocalStorage,
  goToTebPaymentPage,
  isPhoneNumber,
  passengerBaggageForBooking,
  passengerDetailsForBooking,
} from "../../../common/methods";
import { useFlightDetails } from "../../../hooks/useFlightDetails";
import { useCheckFlight } from "../../../hooks/useCheckFlight";
import { BaggageSection } from "./BaggageSection";
import { ManagePassengers } from "./ManagePassenger";
import { AddedBaggageSection } from "./AddedBaggageSection";
import {
  FormWithConstraints,
  FieldFeedbacks,
  FieldFeedback,
} from "react-form-with-constraints";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { set } from "date-fns";
import { Add } from "@mui/icons-material";
import { IsMobileOrBrowser } from "../../../hooks/isBrowserOrMobile";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFlightRouteDetails } from "../../../hooks/useFlightRouteDetails";
import { useTimeDifference } from "../../../hooks/useTimeDifference";
import TripSummaryItem from "./TripSummaryItem";
import { useStore } from "zustand";
import moment from "moment/moment";

const FlightUtil = new FlightUtils();
export const FlightDetails = ({ flight }) => {

  flight ? flight = flight : flight = JSON.parse(localStorage.getItem('currentBooking'))
  const navigate = useNavigate();
  const getpreciseSearchData = useStoreFly((state) => state.getpreciseSearchData);
  const translation = useTranslation();
  const isMobile = IsMobileOrBrowser();
  const returnOrOneWay = useStoreFly((state) => state.returnOrOneWay);
  const baggage = useStoreFly((state) => state.baggage);
  const { passengers } = useFlightDetails(flight);
  const [consent, setConsent] = useState(false);
  const [disableBookingButton, setDisableBookingButton] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);

  const [countries, setCountries] = useState([]);
  const [disableAddBaggage, setDisableAddBaggage] = useState(false);
  const { ssl, passport } = useStaticImages();
  const currency = useStoreFly((state) => state.currency);
  const carriers = useStoreFly((state) => state.airlines);
  const { adult = 0, children = 0, babies = 0 } = passengers;
  const totalPassengers = FlightUtil.getTotalPassenger(adult, children, babies);
  const [currentTotalPassengers, setCurrentTotalPassengers] =
    useState(totalPassengers);
  const [bookingDetails, setBookingDetails] = useState();
  const [freshPriceDialog, setFreshPriceDialog] = useState(false);
  const [addPassengerDetails, setAddPassengerDetails] = useState([]);
  const [passagersForFinalBooking, setFinalPassagersForBooking] = useState();
  const [mainPassengerForm, setMainPassengerForm] = useState(
    MAIN_PASSENGER_INITIAL_FORM
  );
  const [addedBaggage, setAddedBaggage] = useState([]);
  const [noCheckedBaggage, setNoCheckedBaggage] = useState(false);
  const [promoCode, setPromoCode] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState("");
  const [newBaggageItem, setNewBaggageItem] = useState([]);
  const [preciseBookingData, setPreciseBookingData] = useState();

  const [totalBaggagePrice, setTotalBaggagePrice] = useState(0);
  const [passengersList, setPassengersList] = useState([]);
  const [formRef, setFormRef] = useState(null);
  const [redirectMessage, setRedirectMessage] = useState("");
  const [messageSeverity, setMessageSeverity] = useState("success");
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);
  const [voucherInfo, setVoucherInfo] = useState({
    message: "",
    code: 0,
    loading: false,
  });
  useEffect(() => {
    if (!addPassengerDetails.length) {
      const generateAddPassengerList = {
        adults: {
          visible: false,
          total: adult,
        },
        children: {
          visible: false,
          total: children,
        },
        infants: {
          visible: false,
          total: babies,
        },
      };
      setAddPassengerDetails(generateAddPassengerList || []);
    }
  }, [
    addPassengerDetails.length,
    adult,
    babies,
    children,
    currentTotalPassengers,
  ]);

  const {
    price,
    bookingFlightDetails,
    handBagDimensions,
    holdBagDimensions,
    holdBagsToPay,
    setPrice,
    baggageInfo,
  } = useCheckFlight(
    flight,
    currency,
    adult,
    children,
    babies,
    flight?.bnum || baggage?.cabin + baggage?.checked || 0
  );
  let totalBaggFinal = useStoreFly((state) => state.ancillary)

  function dateFormat(date) {
    let splitdate = date.split('-')
    return splitdate[2] + '-' + splitdate[1] + '-' + splitdate[0]
  }
  function returnAncillaries(passagers) { 
    let finalAncillary = []
    let bags = totalBaggFinal
    let allData = []
    if (localStorage.getItem('ancillaryData') !== "undefined") {
      allData = JSON.parse(localStorage.getItem('ancillaryData'))
    }
    let passagerIds = []
    passagerIds = bags.map((el) => el.id)
    passagerIds = passagerIds.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      [],
    );


    let finalBags = []

    bags.forEach((bag) => {
      allData.forEach((data) => {
        data.ancillary.ancillaries.baggage.forEach((ancBag) => {
          if (bag.ancillary.ancillaryKey === ancBag.ancillaryKey) {
            // ancBag.baggagePiece = 1;
            let obj = {
              ...ancBag,

              ancillaryJourneys: data.ancillary.journeys
            }
            if (!obj.description) {
              obj.description = 'test'
            }
            let pass = passagers[bag.id - 1] 
            let passObj = {
              "firstName": pass.firstName?pass.firstName:pass.name,
              "lastName": pass.lastName?pass.lastName:pass.surname,
              "psgType": pass.category==='adult'?'ADT':pass.category==='child'?'CHD':'INF',
              // "sex": pass.sex
            }
            finalAncillary.push({
              ancillaries: {
                baggage: [obj]
              },
              ancillaryPassenger: passObj
            })
          }
        })

      })
    })
    const mergeBaggage = (data) => {
      const passengerMap = {};

      data.forEach(item => {
        const { firstName, lastName } = item.ancillaryPassenger;
        const key = `${firstName} ${lastName}`;

        if (!passengerMap[key]) {
          passengerMap[key] = {
            ...item,
            ancillaries: {
              baggage: []
            }
          };
        }

        passengerMap[key].ancillaries.baggage = passengerMap[key].ancillaries.baggage.concat(item.ancillaries.baggage);
      });

      return Object.values(passengerMap);
    };


    return mergeBaggage(finalAncillary)

  }
  function returnFinalBagg(bags = []) {
    let price = 0;
    if (bags && bags.length > 0) {
      bags.forEach((el) => {
        price += el.ancillary.baggagePrice
      })
    }
    return Number(price)
  }

  const [currentPrice, setCurrentPrice] = useState(price);
  const [firstPrice, secondPrice] = formatPrice(
    addPercentageToPrice(returnFinalBagg(totalBaggFinal) + (currentPrice || price))
  );
  const [passengersPriceFirst, passengerPriceSecond] = formatPrice(
    addPercentageToPrice(currentPrice || price)
  );

  const {
    airlineOutbound,
    airlineInbound,
    ibCityCodeFrom,
    ibCityCodeTo,
    obCityCodeTo,
    obCityFrom,
    obCityTo,
    obArrivalTime,
    obDepartureTime,
    obCityCodeFrom,
    obMinutes,
    obHours,
    obStops,
    ibArrivalTime,
    ibDepartureTime,
    ibHours,
    ibMinutes,
    ibStops,
    obLocalDep,
    obLocalArr,
    ibLocalDep,
    ibLocalArr,
  } = FlightUtil.flightDetailsByFlight(flight, carriers);



  useEffect(() => {
    const timer = setTimeout(() => {
      setFreshPriceDialog(true)
      
    }, 300000); // after 5 minutes

    const getCountries = async () => {
      const data = await GET(GET_LIST_COUNTRY_ALL);
      const favCountries = [];
      data?.forEach((c) => {
        if (c.name.common === "Kosovo" || c.name.common === "Albania") {
          favCountries.push({
            name: c?.name?.common,
            code: c?.cca2,
          });
        }
      });
      const otherCountries = data
        ?.map((country) => ({
          name: country?.name?.common,
          code: country?.cca2,
        }))
        .filter((x) => x.name !== "Kosovo" && x.name !== "Albania");
      setCountries([...favCountries, ...otherCountries]);
      return data;
    };
    getCountries();
    return () => {
      clearTimeout(timer);
    }
  }, []);

  let finalBagg = (useStoreFly((state) => state.ancillary))
  const setAddedBaggageHandler = (newBaggage, id, action) => {
    if (action === "remove") {
      const itemToRemove = addedBaggage?.find(
        (baggage) => baggage.id === id && baggage.price === newBaggage?.price
      );
      const baggages = addedBaggage?.filter((b) => b.id !== itemToRemove?.id);
      const updatedItem = [
        {
          id,
          type: "Checked Baggage",
          price: Number(totalBaggagePrice) - Number(itemToRemove?.price),
          currency: CURRENCY_SIGN[currency?.curr],
        },
      ];
      setAddedBaggage(baggages);
      setTotalBaggagePrice(
        Number(totalBaggagePrice) - Number(itemToRemove?.price)
      );
      setNewBaggageItem(updatedItem);
    }

    if (action === "add") {
      const totalBaggage = [...addedBaggage, ...newBaggage];
      const totalBaggagePrice = totalBaggage
        ?.map((baggage) => baggage.price)
        .reduce((acc, price) => {
          return Number(acc) + Number(price);
        }, 0);
      const item = [
        {
          id,
          type: "Checked Baggage",
          price: totalBaggagePrice,
          currency: CURRENCY_SIGN[currency?.curr],
        },
      ];
      setAddedBaggage([...addedBaggage, ...newBaggage]);
      setTotalBaggagePrice(totalBaggagePrice);
      setNewBaggageItem(item);
    }
  };

  const removeWhenNoCheckedBaggage = (id, bags) => {
    const bnum = getItemFromLocalStorage("bnum");
    const quantity = bags?.find((el) => el.id === id)?.quantity;
    if (quantity) {
      localStorage.setItem(
        "bnum",
        JSON.stringify({
          ...bnum,
          checked: bnum?.checked - quantity,
        })
      );
    }
    const [{ price }] = finalBagg?.length
      ? finalBagg
      : [{ price: 0 }];
    const baggages = finalBagg;
    const totalBaggagePrice = baggages
      ?.map((baggage) => baggage.ancillary.baggagePrice)
      .reduce((acc, price) => {
        return Number(acc) + Number(price);
      }, 0);
    const updatedBaggages = addedBaggage;
    const updatedItem = [
      {
        id,
        type: "Checked Baggage",
        price: price - Number(totalBaggagePrice),
        currency: CURRENCY_SIGN[currency?.curr],
      },
    ];

    setAddedBaggage(updatedBaggages);
    setTotalBaggagePrice(price - Number(totalBaggagePrice));
    setNewBaggageItem(updatedItem);
  };

  const submitBooking = async () => {
    try {
      setBookingLoading(true);
      setDisableBookingButton(true);

      await formRef.validateFields();

      if (!formRef.isValid()) {
        setBookingLoading(false);
        setDisableBookingButton(false);

        if (!formRef.isValid()) {
          const errorFields = formRef.fieldsStore.fields;
          const firstError = errorFields?.find(
            (errField) =>
              errField.validations[0].type === "error" &&
              errField.validations[0].show
          );

          if (firstError) {
            firstError.element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
        return;
      }

      setRedirectMessage(
        "Please wait for a few seconds while we verify your information"
      );
      setMessageSeverity("info");
      setShowRedirectMessage(true);
      const bnum = getItemFromLocalStorage("bnum");


      await sendBookingRequest()

      const response = JSON.parse(localStorage.getItem('preciseBookingOrder'))
      // const response = await GET( 
      //   `${BOOKING_CHECK_FLIGHT}?booking_token=${flight?.booking_token}&bnum=${bnum?.checked || 0
      //   }&adults=${adult}&children=${children}&infants=${babies}&currency=${currency?.curr
      //   }`,
      //   {},
      //   ITRAVEL_KEY_API
      // );

      if (
        response === 'undefined'
      ) {
        alert(
          "This flight is invalid and has expired",
          navigate("/")
        );
        setCurrentPrice(addPercentageToPrice(response?.total));
      }
      // console.log({
      //   passengersList,
      //   mainPassengerForm,
      //   addedBaggage,
      // });
      const passengerList =
        passengersList?.map((p) =>
          passengerDetailsForBooking({
            ...p.form,
            category: p.passengerType,
            email: "info@fluturolire.com", // SENDER_EMAIL
            phone: mainPassengerForm?.phone,
          })
        ) || [];

      const passengersToBook = [
        passengerDetailsForBooking(mainPassengerForm),
        ...passengerList,
      ];

      const bookingDetails = passengerBaggageForBooking(
        passengersToBook,
        {
          combinations: response?.baggage?.combinations || [],
          definitions: response?.baggage?.definitions || [],
        },
        addedBaggage,
        flight?.bnum
      );
      const currentStorageBooking = getItemFromLocalStorage("currentBooking");
      const currentBookingToken = flight?.booking_token || currentStorageBooking?.booking_token;

      // const bookingResponse = await FlightUtil.saveBooking(
      //   "en",
      //   "en",
      //   bookingDetails?.passengers,
      //   currentBookingToken,
      //   response?.session_id,
      //   bookingDetails?.baggage
      // );

      if (response === 'undefined') {
        setMessageSeverity("error");
        setRedirectMessage(
          "Error while saving booking. Please try again and check if you have enter your information correctly!"
        );
        setShowRedirectMessage(true);
        setBookingLoading(false);
        setDisableBookingButton(false);
      }

      if (
        response !== 'undefined'
      ) {
        const passengersList = encodeURIComponent(
          JSON.stringify(passengerList)
        );
        function createBookingId(orderNum) {
          let a = orderNum.slice(0, 8)
          let b = orderNum.slice(9, 17)
          return Number(a) + Number(b)
        }
        //  ${FLUTURO_LIRE_REDIRECT}?email=${mainPassengerForm?.email}&booking_id=${bookingResponse?.booking_id}&transaction_id=${bookingResponse?.transaction_id}&name=${mainPassengerForm?.name}&from_destination=${obCityFrom}&to_destination=${obCityTo}&booking_token=${currentBookingToken}`
        response.data.bookingId = createBookingId(response.data.orderNum)
        const u = MAIN_LAMBDA + '/accept-teb-final-ok'
        const uf = MAIN_LAMBDA + '/accept-teb-final-fail'
        const okUrl = `${u}?email=${mainPassengerForm?.email}&name=${mainPassengerForm?.name}&orderNum=${response.data.orderNum}&bookingId=${response.data.bookingId}&telNum=${mainPassengerForm?.phone}&PNR=${response.data.pnr}/`;
        const failUrl = `${uf}?email=${mainPassengerForm?.email}&name=${mainPassengerForm?.name}&bookingId=${response.data.bookingId}&orderNum=${response.data.orderNum}&telNum=${mainPassengerForm?.phone}&PNR=${response.data.pnr}/`;
        await POST(`${MAIN_LAMBDA}/saveBookingResponse`, {
          body: JSON.stringify({
            ...response,
            name: mainPassengerForm?.name,
            lastName: mainPassengerForm?.surname,
            email: mainPassengerForm?.email,
            from_destination: obCityFrom,
            to_destination: obCityTo,
            booking_token: currentBookingToken,
          }),
        });

        if (response !== 'undefined') {
          setMessageSeverity("success");
          setRedirectMessage("Redirecting to payment page!");
          setShowRedirectMessage(true);
        }
        console.log('okUrl', okUrl)
        await goToTebPaymentPage(
          addPercentageToPrice(totalBaggagePrice + currentPrice).toString(),
          currency?.curr,
          okUrl,
          // okUrl,
          failUrl,
          setBookingLoading,
          setDisableBookingButton
        );
      }
    } catch (error) {
      console.log('error', error)
      setMessageSeverity("error");
      setRedirectMessage("Something went wrong. Please try again!");
      setBookingLoading(false);
      setDisableBookingButton(false);
      setShowRedirectMessage(true);
    }
  };
  const [currentImage, setCurrentImage] = useState("");
  useEffect(() => {
    const getCityToImage = async () => {
      const response = await GET(
        `https://api.teleport.org/api/urban_areas/slug:${flight.cityTo?.toLowerCase()}/images/`
      );
      const imageUrl = response?.photos[0]?.image?.web;
      setCurrentImage(imageUrl);
    };
    getCityToImage();
  }, [flight?.cityTo]);
  const mobileAirlineDimensions = window.innerWidth < 801 ? "32x32" : "32x32";

  // TODO Mi shfaq krejt routes depart and return jo veq tparen edhe te fundit
  // me shfaq flight number afer logos Flight edhe numri
  // te PRN MILAN mi shfaq emrin e aeroportit edhe te dita me shfaq diten edhe daten psh Wednesday Oct 04

  async function sendBookingRequest() {
    var myHeaders = new Headers();
    myHeaders.append("User-Agent", "Apifox/1.0.0 (https://apifox.com)");
    myHeaders.append("Content-Type", "application/json");
    let finalFlight = JSON.parse(localStorage.getItem('preciseSearchData'))[0] || undefined
    console.log('finalFlight', finalFlight)

    finalFlight?.journeys?.journey_0?.segmentIds?.forEach((seg) => {
      // "airline": "FD",
      //           "flightNum": "503",
      //           "arrival": "DMK",
      //           "arrivalDate": "2024-12-31",
      //           "arrivalTime": "00:20",
      //           "departure": "HKG",
      //           "departureDate": "2024-12-30",
      //           "departureTime": "22:20",
      //           "bookingCode": "A"
      delete seg.isReturn
      delete seg.fareBasis
      delete seg.stopover
      delete seg.opFltAirline
      delete seg.opFltNo
      delete seg.codeShare
      delete seg.stayTime
      delete seg.flightTime
      delete seg.arrivalTerminal
      delete seg.departureTerminal
      delete seg.availabilityCount
      // delete seg.bookingCode
      delete seg.cabinClass
      delete seg.equipment
      delete seg.segmentId
      seg.arrivalDate = seg.strArrivalDate
      seg.departureDate = seg.strDepartureDate
      seg.arrivalTime = seg.strArrivalTime
      seg.departureTime = seg.strDepartureTime
      delete seg.strArrivalDate
      delete seg.strDepartureDate
      delete seg.strArrivalTime
      delete seg.strDepartureTime
      delete seg.ancillary
      delete seg.baggageInfo
    })
    finalFlight?.journeys?.journey_1?.segmentIds?.forEach((seg) => {
      // "airline": "FD",
      //           "flightNum": "503",
      //           "arrival": "DMK",
      //           "arrivalDate": "2024-12-31",
      //           "arrivalTime": "00:20",
      //           "departure": "HKG",
      //           "departureDate": "2024-12-30",
      //           "departureTime": "22:20",
      //           "bookingCode": "A"
      delete seg.isReturn
      delete seg.fareBasis
      delete seg.stopover
      delete seg.opFltAirline
      delete seg.opFltNo
      delete seg.codeShare
      delete seg.stayTime
      delete seg.flightTime
      delete seg.arrivalTerminal
      delete seg.departureTerminal
      delete seg.availabilityCount
      // delete seg.bookingCode
      delete seg.cabinClass
      delete seg.equipment
      delete seg.segmentId
      seg.arrivalDate = seg.strArrivalDate
      seg.departureDate = seg.strDepartureDate
      seg.arrivalTime = seg.strArrivalTime
      seg.departureTime = seg.strDepartureTime
      delete seg.strArrivalDate
      delete seg.strDepartureDate
      delete seg.strArrivalTime
      delete seg.strDepartureTime
      delete seg.ancillary
      delete seg.baggageInfo
    })
    const passengerList =
      passengersList?.map((p) =>
        passengerDetailsForBooking({
          ...p.form,
          category: p.passengerType,
          email: "info@fluturolire.com", // SENDER_EMAIL
          phone: mainPassengerForm?.phone,
        })
      ) || [];
    const passengersToBook = [
      passengerDetailsForBooking(mainPassengerForm),
      ...passengerList,
    ]; 

    function returnPassagers(passagers = []) {
      let a = passagers
      let arr = []
      a.forEach((el) => {
        arr.push(
          {
          firstName: el.name,
          nationality: el.nationality,
          lastName: el.surname,
          psgType: el.category==='adult'?'ADT':el.category==='child'?'CHD':'INF',
          sex: el.title === 'mr' ? 'M' : 'F',
          birthday: moment(el.birthday).format('YYYY-MM-DD')
      })
    })
    return arr
  }





  var raw = JSON.stringify({
    "authentication": {
      "sign": SIGNATURE,
      "partnerId": PARTNERID
    },
    "booking": {
      "passengers": returnPassagers(passengersToBook),
      "solution": {
        "adtFare": finalFlight.adtFare,
        "solutionId": finalFlight.solutionId,
        "adtTax": finalFlight.adtTax,
        "chdFare": finalFlight.chdFare,
        "chdTax": finalFlight.chdTax,
        "infFare": finalFlight.infFare ? finalFlight.infFare : 0,
        "infTax": finalFlight.infTax ? finalFlight.infTax : 0,
        "journeys": {
          journey_0:
            finalFlight.journeys.journey_0.segmentIds
          ,
          journey_1:
            finalFlight.journeys.journey_1.segmentIds
        }

      },
      "ancillary": returnAncillaries(passengersToBook)
    }
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  await fetch(MAIN_LAMBDA + "/precise-booking", requestOptions)
    .then(response => response.json())
    .then(async result => {
      localStorage.setItem('preciseBookingOrder', JSON.stringify(result))

       
    })
    .catch(error => console.log('error', error));




}



return (
  <section class="info">
    <div class="container">
      <div
        class="row row-no-gap"
        style={
          isMobile
            ? {
              marginRight: 0,
              marginLeft: 0,
              gap: 0,
            }
            : {}
        }
      >
        <div class="col-lg-12 payinfo mb-5 whitebox col-md-12 col-12">
          <div class="row align-items-center row-no-gap">
            <div class="col-lg-6 col-md-6 col-12">
              <h2 class="m-0">
                {translation.t("nearly_done")}:{" "}
                {translation.t("add_passenger_details")}
              </h2>
            </div>
            <div class="col-lg-5 ml-auto text-right col-md-6 col-12">
              <p class="d-flex w-auto ml-auto align-items-center">
                {translation.t("ssl")}
                <img class="ml-4" srcSet={ssl} alt={"ssls"} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-no-gap">
        <div class="col-lg-9 col-md-12 col-12">
          <div class="row row-no-gap">
            <div class="col-lg-12 col-md-12 col-12">
              <p class="d-flex w-auto lg align-items-center">
                <img class="mr-2" srcSet={passport} alt="passport" />
                <span>
                  {translation.t("passenger_details")}
                  <br />
                  <span>{translation.t("given_info")}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row  mt-5">
        <div class="col-lg-8 col-md-10 col-12">
          <div class="row ">
            <div class="col-lg-9 col-md-12 col-12">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <h4 class="mt-0 mb-3">{translation.t("trip_summary")}</h4>
                </div>
              </div>
              {flight.journeys?.journey_0?.segmengtIds?.map((r) => {
                if (!r.isReturn) {
                  return (
                    <TripSummaryItem
                      flight={flight}
                      route={r}
                      translation={translation}
                      carriers={carriers}
                      type={"depart"}
                    />
                  );
                }
                return null;
              })}
              {flight.journeys?.journey_1?.segmengtIds?.map((r) => {
                if (r.isReturn) {
                  return (
                    <TripSummaryItem
                      flight={flight}
                      route={r}
                      translation={translation}
                      carriers={carriers}
                      type={"return"}
                    />
                  );
                }
                return null;
              })}

              {returnOrOneWay === "return" &&
                flight?.route?.map((r) => {
                  if (r && r.return === 1) {
                    return (
                      <TripSummaryItem
                        flight={flight}
                        route={r}
                        translation={translation}
                        carriers={carriers}
                        type={"return"}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          </div>
          <div
            class="row row-no-gap"
            style={{
              gap: 0,
            }}
          >
            <FormWithConstraints
              ref={(form) => {
                if (form) {
                  setFormRef(form);
                }
              }}
              // onSubmit={this.contactSubmit}
              noValidate
            >
              <div class="col-lg-12 mt-4 col-md-12 col-12">
                <h4>{translation.t("passenger_details")}</h4>
              </div>
              <div class="col-lg-12 d-flex mb-4 col-md-12 col-12">
                <div class="custom-control custom-radio custom-control border-bottom-0 tick col-auto">
                  <input
                    type="radio"
                    id="customRadio-filter-Mr"
                    value="mr"
                    required
                    name="customRadio"
                    class="pointer custom-control-input"
                    onChange={(event) => {
                      formRef.validateFields(event.target);
                      setMainPassengerForm({
                        ...mainPassengerForm,
                        title: event.target.value,
                      });
                    }}
                    defaultChecked="mr"
                  />
                  <label
                    class="custom-control-label d-flex align-items-center"
                    for="customRadio-filter-Mr"
                  >
                    {translation.t("mr")}
                  </label>
                </div>
                <div class="custom-control custom-radio custom-control border-bottom-0 tick col-auto">
                  <input
                    type="radio"
                    id="customRadio-filter-Ms"
                    name="customRadio"
                    class="pointer custom-control-input"
                    value="ms"
                    onChange={(event) => {
                      formRef.validateFields(event.target);
                      setMainPassengerForm({
                        ...mainPassengerForm,
                        title: event.target.value,
                      });
                    }}
                  />
                  <label
                    class="custom-control-label d-flex align-items-center"
                    for="customRadio-filter-Ms"
                  >
                    {translation.t("ms")}
                  </label>
                </div>
              </div>
              <div class="col-lg-8 col-md-12 col-12">
                <div class="row row-no-gap">
                  <div class="col-lg-6 col-md-6 col-12">
                    {!isMobile && (
                      <label>{translation.t("first_name")}</label>
                    )}
                    <input
                      type="text"
                      name="first_name"
                      class="form-control"
                      pattern="[A-Za-z]+"
                      required
                      placeholder={isMobile && translation.t("first_name")}
                      onChange={(event) => {
                        setMainPassengerForm({
                          ...mainPassengerForm,
                          name: event.target.value,
                        });
                        formRef.validateFields(event.target);
                      }}
                    />
                    <FieldFeedbacks for="first_name">
                      <FieldFeedback when="*">
                        {translation.t("enter_first_name")}
                      </FieldFeedback>
                    </FieldFeedbacks>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    {!isMobile && <label>{translation.t("last_name")}</label>}
                    <input
                      type="text"
                      class="form-control"
                      name="surname"
                      pattern="[A-Za-z]+"
                      required
                      placeholder={isMobile && translation.t("last_name")}
                      onChange={(event) => {
                        setMainPassengerForm({
                          ...mainPassengerForm,
                          surname: event.target.value,
                        });
                        formRef.validateFields(event.target);
                      }}
                    />
                    <FieldFeedbacks for="surname">
                      <FieldFeedback when="*" >
                        {translation.t("enter_last_name")}
                      </FieldFeedback>
                    </FieldFeedbacks>
                  </div>
                  <div class="col-lg-12 col-md-6 col-12">
                    {!isMobile && (
                      <label>{translation.t("nationality")}</label>
                    )}
                    <select
                      class="form-control"
                      required
                      placeholder={isMobile && translation.t("nationality")}
                      onChange={(event) => {
                        const code = countries?.find(
                          (c) => c.name === event.target.value
                        )?.code;
                        setMainPassengerForm({
                          ...mainPassengerForm,
                          nationality: code, // check ISO code for countries
                        });
                      }}
                    >
                      {countries?.map((country) => (
                        <option
                          id={country?.code}
                          selected={country?.name === "Kosovo"}
                          value={country?.name}
                        >
                          {country?.name === "Kosovo"
                            ? "Kosovar"
                            : country?.name === "Albania"
                              ? "Shqiptar"
                              : country?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div class="col-lg-12 col-md-12 col-12">
                    {!isMobile && (
                      <label>{translation.t("date_of_birth")}</label>
                    )}
                  </div>
                  <div class="bg-white-box">
                    <div class="row justify-content-between">
                      <div class="col-lg-3 col-md-3 col-12">
                        <input
                          type="number"
                          style={{ marginBottom: isMobile && "0" }}
                          class="form-control datepicker"
                          placeholder={
                            isMobile
                              ? translation.t("DDL")
                              : translation.t("DD")
                          }
                          name="date_of_birth"
                          min={1}
                          required
                          onChange={(event) => {
                            setMainPassengerForm({
                              ...mainPassengerForm,
                              dayOfBirth: event.target.value,
                            });
                            formRef.validateFields(event.target);
                          }}
                        />
                        <FieldFeedbacks for="date_of_birth">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                      <div class="col-lg-5 col-md-5 col-12">
                        <select
                          style={{
                            marginBottom: isMobile && "0",
                          }}
                          class="form-control"
                          onChange={(event) =>
                            setMainPassengerForm({
                              ...mainPassengerForm,
                              monthOfBirth: event.target.value,
                            })
                          }
                        >
                          <option value="January">
                            {translation?.i18n?.language === "al"
                              ? translation.t("jan")
                              : "January"}
                          </option>
                          <option value="February">
                            {translation?.i18n?.language === "al"
                              ? translation.t("feb")
                              : "February"}
                          </option>
                          <option value="March">
                            {translation?.i18n?.language === "al"
                              ? translation.t("mar")
                              : "March"}
                          </option>
                          <option value={"April"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("apr")
                              : "April"}
                          </option>
                          <option value={"May"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("may")
                              : "May"}
                          </option>
                          <option value={"June"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("jun")
                              : "June"}
                          </option>
                          <option value={"July"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("jul")
                              : "July"}
                          </option>
                          <option value={"August"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("aug")
                              : "August"}
                          </option>
                          <option value={"September"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("sep")
                              : "September"}
                          </option>
                          <option value={"October"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("oct")
                              : "October"}
                          </option>
                          <option value={"November"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("nov")
                              : "November"}
                          </option>
                          <option value={"December"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("dec")
                              : "December"}
                          </option>
                        </select>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <input
                          type="number"
                          class="form-control"
                          required
                          name="year_of_birth"
                          placeholder={
                            isMobile
                              ? translation.t("year_of_birth")
                              : translation.t("year")
                          }
                          onChange={(event) => {
                            setMainPassengerForm({
                              ...mainPassengerForm,
                              yearOfBirth: event.target.value,
                            });
                            formRef.validateFields(event.target);
                          }}
                        />
                        <FieldFeedbacks for="year_of_birth">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-12 col-md-12 col-12"
                    style={{ marginTop: !isMobile && "34px" }}
                  >
                    {!isMobile && (
                      <label>{translation.t("passaport_id_or_number")}</label>
                    )}
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder={
                        isMobile
                          ? translation.t("passaport_id_or_number")
                          : ""
                      }
                      name="passport_or_id_number"
                      onChange={(event) => {
                        setMainPassengerForm({
                          ...mainPassengerForm,
                          cardno: event.target.value,
                        });
                        formRef.validateFields(event.target);
                      }}
                    />
                    <FieldFeedbacks for="passport_or_id_number">
                      <FieldFeedback when="*" />
                    </FieldFeedbacks>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    {!isMobile && (
                      <label>
                        {translation.t("passaport_id_or_number_expiry_date")}
                      </label>
                    )}
                  </div>
                  <div class="bg-white-box">
                    <div class="row justify-content-between">
                      <div class="col-lg-3 col-md-3 col-12">
                        <input
                          type="number"
                          class="form-control datepicker"
                          style={{
                            marginBottom: isMobile && "0",
                          }}
                          placeholder={
                            isMobile
                              ? translation.t("day_of_expiry")
                              : translation.t("DD")
                          }
                          required
                          min={1}
                          name="number_expiry_day"
                          onChange={(event) => {
                            setMainPassengerForm({
                              ...mainPassengerForm,
                              dayOfExpiry: event.target.value,
                            });
                            formRef.validateFields(event.target);
                          }}
                        />
                        <FieldFeedbacks for="number_expiry_day">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                      <div class="col-lg-5 col-md-5 col-12">
                        <select
                          class="form-control"
                          style={{
                            marginBottom: isMobile && "0",
                          }}
                          onChange={(event) =>
                            setMainPassengerForm({
                              ...mainPassengerForm,
                              monthOfExpiry: event.target.value,
                            })
                          }
                        >
                          <option value="January">
                            {translation?.i18n?.language === "al"
                              ? translation.t("jan")
                              : "January"}
                          </option>
                          <option value="February">
                            {translation?.i18n?.language === "al"
                              ? translation.t("feb")
                              : "February"}
                          </option>
                          <option value="March">
                            {translation?.i18n?.language === "al"
                              ? translation.t("mar")
                              : "March"}
                          </option>
                          <option value={"April"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("apr")
                              : "April"}
                          </option>
                          <option value={"May"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("may")
                              : "May"}
                          </option>
                          <option value={"June"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("jun")
                              : "June"}
                          </option>
                          <option value={"July"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("jul")
                              : "July"}
                          </option>
                          <option value={"August"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("aug")
                              : "August"}
                          </option>
                          <option value={"September"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("sep")
                              : "September"}
                          </option>
                          <option value={"October"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("oct")
                              : "October"}
                          </option>
                          <option value={"November"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("nov")
                              : "November"}
                          </option>
                          <option value={"December"}>
                            {translation?.i18n?.language === "al"
                              ? translation.t("dec")
                              : "December"}
                          </option>
                        </select>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <input
                          type="number"
                          class="form-control"
                          placeholder={
                            isMobile
                              ? translation.t("year_of_expiry")
                              : translation.t("year")
                          }
                          required
                          min={new Date().getFullYear()}
                          name="passport_or_id_number_expiry_year"
                          onChange={(event) => {
                            setMainPassengerForm({
                              ...mainPassengerForm,
                              yearOfExpiry: event.target.value,
                            });
                            formRef.validateFields(event.target);
                          }}
                        />
                        <FieldFeedbacks for="passport_or_id_number_expiry_year">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-6 col-md-6 col-12"
                    style={{ marginTop: !isMobile && "40px" }}
                  >
                    {!isMobile && <label>Email</label>}
                    <input
                      type="email"
                      class="form-control"
                      placeholder={isMobile ? translation.t("Email") : ""}
                      required
                      name="email"
                      onChange={(event) => {
                        setMainPassengerForm({
                          ...mainPassengerForm,
                          email: event.target.value,
                        });
                        formRef.validateFields(event.target);
                      }}
                    />
                    <FieldFeedbacks for="email">
                      <FieldFeedback when="*" />
                    </FieldFeedbacks>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-12"
                    style={{ marginTop: !isMobile && "40px" }}
                  >
                    {!isMobile && <label>{translation.t("phone")}</label>}
                    <div class="form-group">
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        specialLabel=""
                        inputClass="form-control"
                        inputStyle={{ maxWidth: "100%", width: "100%" }}
                        country={"us"}
                        enableSearch
                        value={"us"}
                        onChange={(phone, _, event) => {
                          setMainPassengerForm({
                            ...mainPassengerForm,
                            phone: phone,
                          });
                          formRef.validateFields(event.target);
                        }}
                      />
                      <FieldFeedbacks for="phone">
                        <FieldFeedback
                          when={(value) => !isPhoneNumber(value)}
                        >
                          Phone number is invalid
                        </FieldFeedback>
                      </FieldFeedbacks>
                    </div>
                  </div>




                  <BaggageSection
                    id={1}
                    key="baggage_section"
                    flight={flight}
                    handBagDimensions={handBagDimensions}
                    holdBagDimensions={holdBagDimensions}
                    holdBagsToPay={holdBagsToPay}
                    disableAddBaggage={disableAddBaggage}
                    setDisableAddBaggage={setDisableAddBaggage}
                    total={bookingFlightDetails?.total}
                    setPrice={setPrice}
                    addedBaggage={addedBaggage}
                    setAddedBaggage={(newBaggage, id, action) =>
                      setAddedBaggageHandler(newBaggage, id, action)
                    }
                    hideBagsToPayOption={noCheckedBaggage}
                    currency={CURRENCY_SIGN[currency?.curr]}
                    currentBnum={flight?.bnum}
                    baggageInfo={baggageInfo}
                  />

                  <div
                    class="custom-control custom-radio custom-control tick col-lg-12 mt-1 col-md-12 col-12"
                    style={{
                      borderBottom: "none",
                    }}
                  >
                    <div className="col-lg-12 mt-1 col-md-12 col-12">
                      <input
                        type="checkbox"
                        id={`nocheckedbaggage_1`}
                        name={`nocheckedbaggage_1`}
                        class="custom-control-input"
                        onChange={(event) => {
                          setNoCheckedBaggage(event.target.checked);
                          removeWhenNoCheckedBaggage(1, addedBaggage);
                        }}
                        checked={noCheckedBaggage}
                      />
                      {/* <label
                          class="custom-control-label"
                          for={`nocheckedbaggage_1`}
                          style={{
                            fontSize: "17px",
                          }}
                        >
                          No Checked Baggage
                        </label> */}
                    </div>
                  </div>
                  <ManagePassengers
                    key="manage_passengers"
                    formRef={formRef}
                    flight={flight}
                    adults={adult}
                    children={children}
                    infants={babies}
                    handBagDimensions={handBagDimensions}
                    holdBagDimensions={holdBagDimensions}
                    holdBagsToPay={holdBagsToPay}
                    countries={countries}
                    setAddedBaggage={(newBaggage, id, action) =>
                      setAddedBaggageHandler(newBaggage, id, action)
                    }
                    addedBaggage={addedBaggage}
                    removeWhenNoCheckedBaggage={(id, bags) =>
                      removeWhenNoCheckedBaggage(id, bags)
                    }
                    currency={currency?.curr}
                    setUpdatedFlight={(updatedFlight) => {
                      setCurrentPrice(updatedFlight?.totalPrice);
                      setCurrentTotalPassengers(
                        updatedFlight?.totalPassengers
                      );
                      setBookingDetails(updatedFlight);
                    }}
                    setPassengers={(list) => setPassengersList(list)}
                  // currentBnum={currentBnum}
                  // setBnum={setBnum}
                  />
                </div>
              </div>
            </FormWithConstraints>
          </div>
          <div
            class="custom-control custom-radio custom-control tick col-lg-12 mt-1 col-md-12 col-12"
            style={{
              borderBottom: "none",
              marginTop: "30px !important",
              paddingLeft: "10px",
            }}
          >
            <div
              className="flex-column"
              style={{ width: "100%", minWidth: "350px" }}
            >
              <div
                className="col-lg-12 mt-4 col-md-12 col-12"
                style={{
                  paddingLeft: "15px",
                  maxHeight: "10px",
                }}
              >
                <input
                  type="checkbox"
                  style={{ minWidth: "300px" }}
                  id={`promo_code`}
                  name={`promo_code`}
                  class="custom-control-input"
                  onChange={(event) => {
                    setPromoCode(event.target.checked);
                  }}
                  checked={promoCode}
                />
                <label
                  class="custom-control-label"
                  for={`promo_code`}
                  style={{
                    fontSize: "17px",
                  }}
                >
                  {translation.t("add_promo_code")}
                </label>
              </div>
              {!!promoCode && (
                <Fragment>
                  <div
                    className="col-lg-8 mt-4 col-md-3 col-3 flex-row"
                    style={{
                      alignItems: "baseline",
                      gap: "30px",
                      paddingLeft: 0,
                      minWidth: "350px",
                    }}
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      required
                      name="promo_code"
                      onChange={(event) => {
                        setPromoCodeValue(event.target.value);
                      }}
                    />
                    <Button
                      variant="contained"
                      // startIcon={<Add />}
                      sx={{
                        borderRadius: "15px",
                        background: "#003663",
                        "&:hover": { background: "#003663;" },
                      }}
                      disabled={!promoCodeValue || voucherInfo?.loading}
                      onClick={async () => {
                        setVoucherInfo((prev) => ({
                          ...prev,
                          loading: true,
                        }));
                        try {
                          const applyVoucherResponse = await POST(
                            `${MAIN_LAMBDA}/apply-voucher`,
                            {
                              body: JSON.stringify({
                                voucher: promoCodeValue,
                              }),
                            }
                          );

                          if (applyVoucherResponse.statusCode === 400) {
                            setVoucherInfo({
                              message: applyVoucherResponse.errorMessage,
                              code: 400,
                              loading: false,
                            });
                          }

                          if (
                            applyVoucherResponse.statusCode === 200 &&
                            applyVoucherResponse.amount
                          ) {
                            setVoucherInfo({
                              message: "Voucher applied successfully!",
                              code: 200,
                              loading: false,
                            });
                            setCurrentPrice(
                              currentPrice -
                              Number(applyVoucherResponse.amount)
                            );
                          }
                        } catch (err) {
                          setVoucherInfo({
                            message: "Something went wrong!",
                            code: 500,
                            loading: false,
                          });
                        }
                      }}
                    >
                      {translation.t("apply")}
                    </Button>
                  </div>
                  {!!voucherInfo?.message && (
                    <Alert severity={CODE_TO_SEVERITY[voucherInfo?.code]}>
                      {voucherInfo?.message}
                    </Alert>
                  )}
                </Fragment>
              )}
            </div>
          </div>
          <div
            class="custom-control custom-radio custom-control tick col-lg-12 mt-1 col-md-12 col-12"
            style={{
              borderBottom: "none",
              marginTop: "30px !important",
            }}
          ></div>
        </div>
        <div
          class="col-lg-4 mt-3 mt-lg-0 mt-md-4 pt-2 col-md-6 ml-auto col-12"
          style={{ padding: "0 15px" }}
        >
          <div class="col-lg-12 whitebox totaldiv  pb-3 col-md-12 col-12 sticky">
            <div class="row">
              <div class="col-lg-12 space-tiny col-md-12 col-12">
                <div
                  class="col-lg-12 mb-3 imgbox col-md-12 col-12"
                  style={{
                    minHeight: "100px",
                    background: `linear-gradient(rgba(0, 54, 99, 0.43), rgba(0, 54, 99, 0.43)),
    url(${currentImage || "https://images.kiwi.com/photos/472x440/photos.jpg"
                      }) no-repeat 50% 50% / cover`,
                  }}
                >
                  <h6>
                    {obCityFrom} - {obCityTo}
                  </h6>
                  {/* <span>Nalaguraidhoo, Nalaguraidhoo</span>
									<div class="stars">
									</div> */}
                </div>
              </div>
              <div class="col-lg-12 mb-4 mt-3 total-text col-md-12 col-12">
                <div class="itemdetail">
                  {currentTotalPassengers}× {translation.t("passenger")}
                  {currentTotalPassengers > 1 && "'s"}{" "}
                  <span>
                    {passengersPriceFirst}
                    <sup>{passengerPriceSecond || ""}</sup>
                    {CURRENCY_SIGN[currency?.curr]}
                  </span>
                </div>
                <div class="itemdetail">
                  {translation.t("personal_baggage")}{" "}
                  <span> {translation.t("free")}</span>
                </div>
                {finalBagg?.[0]?.ancillary.baggagePrice > 0 && (
                  <AddedBaggageSection
                    baggageList={finalBagg}
                    key="added_baggage_section"
                  />
                )}
                <hr class="border-grey mt-2 mb-4" />
                <p class="total-price">
                  {translation.t("total_price")}{" "}
                  <span>
                    {firstPrice}
                    <sup>{secondPrice || ""}</sup>
                    {CURRENCY_SIGN[currency?.curr]}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 mt-5 col-md-12 col-12">
          <div
            class="custom-control custom-radio custom-control tick"
            style={{ borderBottom: "none" }}
          >
            <input
              type="checkbox"
              id="consent"
              name="consent"
              class="custom-control-input"
              onChange={(event) => {
                setConsent(event.target.checked);
              }}
              checked={consent}
            />
            <label
              class="custom-control-label"
              for="consent"
              style={{ fontSize: "17px" }}
            >
              {translation.t("accept")}{" "}
              <a href="/terms-conditions" target="_blank">
                {translation.t("terms_and_conditions")}
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank">
                {translation.t("privacy_policy")}
              </a>
            </label>
          </div>
        </div>
        <div class="col-lg-12 mt-5 text-right col-md-12 col-12">
          <button
            type="submit"
            id="buttonPayment"
            disabled={!consent || disableBookingButton}
            class="btn-submit default-yellow-btn"
            style={
              bookingLoading
                ? { paddingLeft: "10px", fontWeight: "bold", fontSize: "15" }
                : {
                  padding: "10px 40px",
                  fontWeight: "bold",
                  fontSize: "15",
                  maxWidth: "265px",
                }
            }
            onClick={async () => submitBooking()}
          >
            {!!bookingLoading && (
              <CircularProgress
                size={20}
                style={{
                  marginTop: "10px",
                  marginRight: "10px",
                }}
              />
            )}
            {translation.t("book_now")}
            <span>
              {firstPrice}
              <sup>{secondPrice || ""}</sup>
              {CURRENCY_SIGN[currency?.curr]}
            </span>
          </button>
          {showRedirectMessage && (
            <Alert severity={messageSeverity}>{redirectMessage}</Alert>
          )}
        </div>
      </div>
    </div>
    <div>
      <Dialog
        onClose={() => { }}
        // aria-labelledby="customized-dialog-title"
        open={freshPriceDialog}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "5px"
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <span style={{
            color: "#003663",
            fontWeight: "bold"
          }} className="d-inline-block price-text w-100">{translation && translation.t("hurry_up")}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setFreshPriceDialog(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <Illustration name="Time" size="small" /> */}
          <h6 class="mt-4" style={{
            color: "#003663"
          }}>{translation && translation.t("remember_limited_time")}</h6>

        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setFreshPriceDialog(false);
              // navigate("/");
            }}
            className="default-yellow-btn mt-3 select"
            style={{
              borderRadius: "11px",
              color: "#003663",
              background: "#fbd316",
              marginRight: "10px",
              marginBottom: "10px",
              fontWeight: "bolder"
            }}
          >
            {translation && translation.t("got_it")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </section>
);
};
