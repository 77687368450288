import Header from "../../../components/Header";
import Banner from "../../../components/Banner";
import Styles from "../../../components/Styles";
import Popular from "../../../components/Popular";
import Copyrights from "../../../components/Copyrights";
import Footer from "../../../components/Footer";
import { useTranslation } from 'react-i18next';
import { IsMobileOrBrowser } from "../../../hooks/isBrowserOrMobile";
// import { MobileHeader } from "../../../components/flight/MobileHeader";

const Home = () => {
  const translationContext = useTranslation();
  const isMobile = IsMobileOrBrowser();
  
  return (
    <div>
      <Styles />
      <Header translation={translationContext.t} translationContext={translationContext} />
      <Banner translation={translationContext.t}/>
      <Popular translation={translationContext.t} style={{paddingTop: isMobile ? 0 : "65px"}}/>
      <Footer />
      <Copyrights />

      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-715107215"></script>
        {
      <script>
          {/* window?.dataLayer = window?.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments)
          }
          gtag('js', new Date());
          gtag('config', 'AW-715107215'); */}
      </script> 
        }
 
      {  
        <script type="text/javascript">
    {(function(c, l, a, r, i, t, y) {
        c[a] = c[a] || function() {
            (c[a].q = c[a].q || []).push(arguments);
        };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "l5onqk3uin")}
    
</script>  

}
</div>
  );
};

export default Home;
