import moment from "moment";
import {
  BAGGAGE_CATEGORY,
  CURRENCY_ISO_CODE,
  MONTH_INDEX,
  TEB_PAYMENT_PARAMS,
} from "../utils/enums";
import { POST } from "../utils/api";
import {
  FLUTURO_LIRE_APPROVE,
  FLUTURO_LIRE_GENERATE_HASH,
  FLUTURO_LIRE_REDIRECT,
  TEB_PAYMENT_ENDPOINT,
} from "../utils/constants";

export const getAirlineNames = (airlinesId = [], airlines = []) => {
  const result = [];
  airlines.forEach((airline) => {
    if (airline && airline.id && airlinesId.includes(airline.id)) {
      result.push(airline);
    }
  });
  return result;
};

export const getInboundAndOutboundAirlineNames = (
  airlinesWithNames = [],
  inboundFlights = [],
  outboundFlights = []
) => {
  const inboundAirlineNames = [];
  const outboundAirLineNames = [];

  airlinesWithNames?.forEach((airlineItem) => {
    inboundFlights?.forEach((inboundFlight) => {
      if (inboundFlight.airline === airlineItem.id)
        inboundAirlineNames.push(airlineItem);
    });
  });

  airlinesWithNames?.forEach((airlineItem) => {
    outboundFlights?.forEach((outboundFlight) => {
      if (outboundFlight.airline === airlineItem.id)
        outboundAirLineNames.push(airlineItem);
    });
  });

  return { inboundAirlineNames, outboundAirLineNames };
};

export const getInboundAndOutboundRoutes = (routes = []) => {
  const inboundFlights = [];
  const outboundFlights = [];
  routes?.forEach((route) => {
    if (route && route.return === 0) {
      outboundFlights.push(route);
    }
    if (route && route.return === 1) {
      inboundFlights.push(route);
    }
  });
  return { inboundFlights, outboundFlights };
};

export const removeDuplicatesFromArray = (data = []) => {
  const result = data.reduce((accumulator, current) => {
    let exists = accumulator.find((item) => {
      return item.id === current.id;
    });
    if (!exists) {
      accumulator = accumulator.concat(current);
    }
    return accumulator;
  }, []);
  return result;
};

export const truncateText = (text = "", limit = 10) => {
  if (text.length > 5) {
    return text.substring(0, limit) + "...";
  }
  return text;
};

export const getFormattedTime = (time) =>
  new Date(time)
    .toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
    ?.toLowerCase();

export const getAMorPM = (date) => {
  const newDate = new Date(date);
  let hours = newDate.getHours();
  let ampm = hours >= 12 ? "pm" : "am";

  return ampm;
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
};

export const tolocaleDate = (date) => new Date(date);
export const getDayValue = (date) =>
  new Date(date).getDate() <= 9
    ? `0${new Date(date).getDate()}`
    : new Date(date).getDate();

export const dayOfWeekAsString = (index) => {
  return (
    [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][index] || ""
  );
};

export const monthAsString = (index) => {
  return {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  }[index];
};

export const getHowManyStops = (routes = []) => {
  let currentRoute = routes[0];
  let count = 1;
  for (let i = 1; i < routes.length; i++) {
    if (routes[i].flyFrom === currentRoute?.flyTo) {
      count++;
    }
    currentRoute = routes[i];
  }
  return count !== 1 ? count - 1 : count;
};

export const totalStops = (routes ) => {
  if(Array.isArray(routes)){
    return routes.length;
  }
  else{
    return 1
  }
  
};

export const updateObjectByPath = (obj, path, value) => {
  const keys = path.split(".");

  let current = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    current = current[keys[i]];
  }
  current[keys[keys.length - 1]] = value;
  return obj;
};

export const calculateTotalTime = (hours1, minutes1, hours2, minutes2) => {
  const totalMinutes = minutes1 + minutes2;
  const totalHours = hours1 + hours2 + Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;
  return { hours: totalHours, minutes: remainingMinutes };
};

export const getTodayAndOneMonthFromNow = (dateFormat = "DD/MM/YYYY") => {
  const currentDate = moment(new Date());
  let futureMonth = moment(currentDate).add(1, "M");
  const futureMonthEnd = moment(futureMonth).endOf("month");

  if (
    currentDate.date() !== futureMonth.date() &&
    futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
  ) {
    futureMonth = futureMonth.add(1, "d");
  }
  const today = currentDate.format(dateFormat);

  const tomorrow = moment(currentDate).add(1, "day");
  const twoDaysFromToday = moment(currentDate).add(2, "day");
  const threeDaysFromToday = moment(currentDate).add(3, "day");
  const fourDaysFromToday = moment(currentDate).add(4, "day");
  const fiveDaysFromToday = moment(currentDate).add(5, "day");
  const sixDaysFromToday = moment(currentDate).add(2, "day");

  const days = {
    1: tomorrow,
    2: twoDaysFromToday,
    3: threeDaysFromToday,
    4: fourDaysFromToday,
    5: fiveDaysFromToday,
    6: sixDaysFromToday,
  };
  const aMonthFromNow = futureMonth.format(dateFormat);

  return {
    today,
    twoDaysFromToday: twoDaysFromToday.format(dateFormat),
    tomorrow: tomorrow.format(dateFormat),
    days,
    aMonthFromNow,
  };
};

export const uniqueId = Date.now() + "-" + Math.floor(Math.random() * 10000);

// Use the filter() and findIndex() methods to remove duplicates by id field
export const uniqueItems = (field = "id", items = []) =>
  items.filter((item, index, self) => {
    return index === self.findIndex((t) => t[field] === item[field]);
  });

export const goToTebPaymentPage = async (
  amount,
  currency = "EUR",
  okUrl,
  failUrl,
  setBookingLoading,
  setDisableBookingButton
) => {
  const hashParams = {
    currency: CURRENCY_ISO_CODE[currency],
    client_id: TEB_PAYMENT_PARAMS.CLIENT_ID,
    storetype: TEB_PAYMENT_PARAMS.STORE_TYPE,
    oid: TEB_PAYMENT_PARAMS.OID,
    amount,
    ok_url: okUrl || FLUTURO_LIRE_REDIRECT,
    fail_url: failUrl || FLUTURO_LIRE_REDIRECT,
    tran_type: TEB_PAYMENT_PARAMS.TRAN_TYPE,
    rnd: TEB_PAYMENT_PARAMS.RND,
    lang:'en',
    hashAlgorithm:'ver3',
    callback_url: FLUTURO_LIRE_APPROVE,
  };

  console.log('hashParams', hashParams)
  const { hash } = await POST(FLUTURO_LIRE_GENERATE_HASH, {
    body: JSON.stringify(hashParams),
  });
  const payload = { 
    amount,
    callbackUrl: FLUTURO_LIRE_APPROVE,
    clientid: TEB_PAYMENT_PARAMS.CLIENT_ID,
    currency: CURRENCY_ISO_CODE[currency],
    // encoding: TEB_PAYMENT_PARAMS.ENCODING,
    failUrl: failUrl || FLUTURO_LIRE_REDIRECT,
    lang: "en",
    oid: TEB_PAYMENT_PARAMS.OID,
    okUrl: okUrl || FLUTURO_LIRE_REDIRECT,
    rnd: TEB_PAYMENT_PARAMS.RND,
    storetype: TEB_PAYMENT_PARAMS.STORE_TYPE,
    tranType: TEB_PAYMENT_PARAMS.TRAN_TYPE,
    hashAlgorithm:'ver3',
    hash
  };

  try { 
      POST_FORM(TEB_PAYMENT_ENDPOINT, payload); 
    setBookingLoading(false);
    setDisableBookingButton(false);
  } catch (error) {
    setBookingLoading(false);
    setDisableBookingButton(false);
    // console.log(error);
  }
};

export const generateHash = async (amount, okUrl, failUrl) => {
  const hashParams = {
    client_id: TEB_PAYMENT_PARAMS.CLIENT_ID,
    oid: TEB_PAYMENT_PARAMS.OID,
    amount,
    ok_url: okUrl || FLUTURO_LIRE_REDIRECT,
    fail_url: failUrl || FLUTURO_LIRE_REDIRECT,
    tran_type: TEB_PAYMENT_PARAMS.TRAN_TYPE,
    rnd: TEB_PAYMENT_PARAMS.RND,
    callback_url: FLUTURO_LIRE_APPROVE,
  };

  const { hash } = await POST(FLUTURO_LIRE_GENERATE_HASH, {
    body: JSON.stringify(hashParams),
  });

  return hash;
};

const POST_FORM = (url, payload) => { 
  const form = document.createElement("form");
  form.method = "post";
  form.action = url;

  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = payload[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form); 
    
    form.submit();  
};

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-4" +
    s4().substr(0, 3) +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  ).toLowerCase();
};

export const passengerDetailsForBooking = (params) => {
  const monthOfBirth =
    MONTH_INDEX[params?.monthOfBirth?.toUpperCase()] || MONTH_INDEX["JANUARY"];
  const monthOfExpiry =
    MONTH_INDEX[params?.monthOfExpiry?.toUpperCase()] || MONTH_INDEX["JANUARY"];
  return {
    birthday: `${params?.yearOfBirth}-${monthOfBirth}-${params?.dayOfBirth}`,
    cardno: params?.cardno,
    category: params?.category,
    email: "info@fluturolire.com",
    expiration: `${params?.yearOfExpiry}-${monthOfExpiry}-${params?.dayOfExpiry}`, // format YYYY-MM-DD
    title: params?.title || "mr", // mr or ms
    name: params?.name,
    surname: params?.surname,
    nationality: params?.nationality || "XK",
    phone: `+${params?.phone}`, // format + or 00 with prefix then number ex: +38343123321
  };
};

const premiumBaggage = (baggageInfo, passengers) => {
  const allHoldBaggageFree = baggageInfo?.combinations?.hold_bag?.every(
    (hb) => hb?.price?.amount === 0
  );
  const allHandBaggageFree = baggageInfo?.combinations?.hand_bag?.every(
    (hb) => hb?.price?.amount === 0
  );
  if (!allHoldBaggageFree && !allHandBaggageFree) return false;

  const holdBaggage = baggageInfo?.combinations?.hold_bag?.map((bi, i) => ({
    combination: {
      indices: Array.from({ length: passengers?.length }, (_, index) => index),
      category: "hold_bag",
      conditions: {
        passenger_groups: bi?.conditions?.passenger_groups,
      },
      price: bi?.price,
    },
    passengers: Array.from({ length: passengers?.length }, (_, index) => index),
  }));

  const handBaggage = baggageInfo?.combinations?.hand_bag?.map((bi, i) => ({
    combination: {
      indices: Array.from({ length: passengers?.length }, (_, index) => index),
      category: "hold_bag",
      conditions: {
        passenger_groups: bi?.conditions?.passenger_groups,
      },
      price: bi?.price,
    },
    passengers: Array.from({ length: passengers?.length }, (_, index) => index),
  }));

  const [adult, children, infants] = passengers?.map(
    (passenger) => passenger.category
  );

  let holdBaggageFiltered = holdBaggage;
  let handBaggageFiltered = handBaggage;

  if (!infants) {
    holdBaggageFiltered = holdBaggage?.filter(
      (hb) =>
        hb?.combination?.conditions?.passenger_groups?.includes("adult") ||
        hb?.combination?.conditions?.passenger_groups?.includes("child")
    );
    handBaggageFiltered = handBaggage?.filter(
      (hb) =>
        hb?.combination?.conditions?.passenger_groups?.includes("adult") ||
        hb?.combination?.conditions?.passenger_groups?.includes("child")
    );
  }

  localStorage.setItem(
    "bnum",
    JSON.stringify({ checked: holdBaggageFiltered.length })
  );

  return {
    holdBaggage: holdBaggageFiltered,
    handBaggage: handBaggageFiltered,
  };
};

export const passengerBaggageForBooking = (
  passengers = [],
  baggageInfo,
  baggageToPay = [],
  bnum = 0
) => {
  const currentSearch = getItemFromLocalStorage("search");

  // console.log({baggageInfo});
  // const businessClassBaggage = baggageInfo?.definitions?.hold_bag?.map(
  //   (bi, i) => ({
  //     index: i,
  //     price: bi?.price?.amount,
  //     category: "hold_bag",
  //     baggage: "Checked Baggage",
  //   })
  // );
  if (currentSearch?.selectedClass === "C") {
    const { holdBaggage, handBaggage } = premiumBaggage(
      baggageInfo,
      passengers
    );
    return {
      passengers,
      baggage: [...holdBaggage, ...handBaggage],
    };
  }

  const infantsOnly = passengers?.filter(
    (passenger) => passenger?.category === "infant"
  );
  const adultsAndChildren = passengers?.filter(
    (passenger) => passenger?.category !== "infant"
  );

  const handBaggageCombinationAdultAndChildren = getBaggageDetails(
    baggageInfo,
    adultsAndChildren,
    "hand_bag",
    "adult",
    adultsAndChildren?.length
  );

  const handBaggageCombinationInfant = getBaggageDetails(
    baggageInfo,
    infantsOnly,
    "hand_bag",
    "infant",
    adultsAndChildren?.length
  );

  const holdBaggageCombinationAdultAndChildren = getBaggageDetailsToPay(
    baggageInfo,
    baggageToPay,
    passengers,
    adultsAndChildren?.length,
    infantsOnly?.length
  );

  const holdBaggageCombinationInfant =
    baggageInfo?.combinations?.hold_bag?.filter((hb) =>
      hb?.conditions?.passenger_groups?.includes("infant")
    ) || [];

  const nonInfantPassengers = passengers?.filter(
    (passenger) => passenger?.category !== "infant"
  );
  const infantHoldBaggageFormatted = holdBaggageCombinationInfant?.map(
    (hbI) => ({
      combination: {
        indices: hbI?.indices,
        category: "hold_bag",
        conditions: hbI?.conditions,
        price: hbI?.price,
      },
      passengers: Array.from(
        { length: infantsOnly?.length },
        (_, index) => index + nonInfantPassengers?.length
      ),
    })
  );

  const handBagAdultsAndChildren = [handBaggageCombinationAdultAndChildren];
  const handBagInfants = [
    {
      combination: handBaggageCombinationInfant?.combination,
      passengers: handBaggageCombinationInfant?.passengers,
    },
  ];

  let handBagDetails = handBagAdultsAndChildren;
  if (infantsOnly?.length) {
    handBagDetails = [...handBagAdultsAndChildren, ...handBagInfants];
  }

  const holdBagDetails = [...holdBaggageCombinationAdultAndChildren];


  localStorage.setItem(
    "bnum",
    JSON.stringify({ checked: holdBagDetails.length })
  );
  return {
    passengers,
    baggage: [...handBagDetails, ...holdBagDetails],
  };
};

const getBaggageDetails = (
  baggageInfo,
  passengersList = [],
  category,
  passenger = "adult",
  adultsLength = 1
) => {
  const handBags = baggageInfo?.combinations?.hand_bag || [];
  const holdBags = baggageInfo?.combinations?.hold_bag || [];
  const length = passengersList?.length;
  const findCombinationByCategory = (passengerType) => {
    if (category === "hand_bag") {
      return handBags.find((item) =>
        item.conditions.passenger_groups.includes(passengerType)
      );
    } else {
      return holdBags.find((item) =>
        item.conditions.passenger_groups.includes(passengerType)
      );
    }
  };

  return {
    combination: {
      indices: findCombinationByCategory(passenger)?.indices,
      category: category,
      conditions: findCombinationByCategory(passenger)?.conditions,
      price: findCombinationByCategory(passenger)?.price,
    },
    passengers: Array.from({ length }, (_, index) =>
      passenger === "infant" ? adultsLength + index : index
    ),
  };
};

const getBaggageDetailsToPay = (
  baggageInfo,
  baggageToPay = [],
  passengers = [],
  adultsAndChildrenLength = 1,
  infantsLength = 0
) => {
  const holdBags = baggageInfo?.combinations?.hold_bag || [];
  const selectedBaggage = passengers?.map((passengerItem, index) => {
    return {
      index,
      type: passengerItem?.category,
      price:
        baggageToPay?.find((baggage) => baggage?.id === index + 1)?.price ||
        null,
      baggage:
        baggageToPay?.find((baggage) => baggage?.id === index + 1) || null,
    };
  });

  const listOfPrices = selectedBaggage
    ?.map((baggage) => baggage?.price)
    ?.filter((y) => y);
  const listOfPricesFiltered = removeDuplicates(listOfPrices);
  const combinationsList = [];
  listOfPricesFiltered
    ?.filter((x) => x)
    ?.forEach((price) => {
      const passengersIndices = selectedBaggage?.filter(
        (sB) => sB.type !== "infant" && sB.price && sB.price === price
      );
      if (price) {
        const findPriceDetails = holdBags?.find(
          (b) => b?.price?.amount === price
        )?.price;
        const findIndices = holdBags?.find(
          (b) => b?.price?.amount === price
        )?.indices;
        const findConditions = holdBags?.find(
          (b) => b?.price?.amount === price
        )?.conditions;
        const newItem = {
          combination: {
            indices: findIndices,
            category: "hold_bag",
            conditions: findConditions,
            price: findPriceDetails,
          },
          passengers: passengersIndices?.map((pI) => pI?.index),
        };
        combinationsList.push(newItem);
      }
    });
  return combinationsList;
};

const removeDuplicates = (arr = []) => {
  return arr.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};

export const saveBookingPassengerAndBaggage = async (
  passengers = [],
  baggages = []
) => {
  const passengerList = passengers?.map((passenger) =>
    passengerDetailsForBooking(passenger)
  );
  const baggageList = baggages?.map((baggage) =>
    passengerBaggageForBooking(baggage)
  );

  return {
    passengers: passengerList,
    baggage: baggageList,
  };
};

export const formatBaggageDefinitions = (
  definitionsHandBag = [],
  definitionHoldBag = []
) => {
  const formattedHandBag = [],
    formattedHoldBag = [];

  definitionHoldBag.forEach((defHoldBag, index) => {
    formattedHoldBag.push({
      index,
      category: defHoldBag.category,
      passengerGroup: defHoldBag?.conditions?.passenger_groups,
      price: defHoldBag?.price,
      dimensions: defHoldBag?.restrictions || null,
      indices: defHoldBag?.indices || null,
    });
  });

  definitionsHandBag.forEach((defHandBag, index) => {
    formattedHandBag.push({
      index,
      category: defHandBag.category,
      passengerGroup: defHandBag?.conditions?.passenger_groups,
      price: defHandBag?.price,
      dimensions: defHandBag?.restrictions || null,
      indices: defHandBag?.indices || null,
    });
  });

  return {
    formattedHandBag,
    formattedHoldBag,
  };
};

export const definitionsWithCombinationsBaggage = (
  definitionHandBags = [],
  definitionHoldBag = [],
  combinationHandBag = [],
  combinationHoldBag = []
) => {
  const handBags = [];
  const holdBags = [];

  combinationHandBag?.forEach((combinationHandBag) => {
    combinationHandBag?.indices?.forEach((indice) => {
      handBags.push({
        ...combinationHandBag,
        category_name: combinationHandBag?.category,
        category_display_name: BAGGAGE_CATEGORY[combinationHandBag?.category],
        dimensions: definitionHandBags?.find(
          (defHandBag) =>
            defHandBag.index === combinationHandBag.indices?.[indice]
        )?.dimensions,
        is_free: combinationHandBag?.price?.amount === 0 || false,
      });
    });
  });

  combinationHoldBag?.forEach((combinationHoldBag) => {
    const newItem = (indice) => ({
      ...combinationHoldBag,
      category_name: combinationHandBag?.category,
      category_display_name: BAGGAGE_CATEGORY[combinationHandBag?.category],
      dimensions:
        definitionHoldBag?.find(
          (defHoldBag) =>
            defHoldBag.index === combinationHoldBag.indices?.[indice]
        )?.dimensions || null,
      is_free: combinationHoldBag?.price?.amount === 0 || false,
      length: 1,
    });

    const allIndicesAreSame = combinationHoldBag?.indices?.length > 1;

    if (allIndicesAreSame) {
      holdBags.push({
        ...newItem(combinationHoldBag?.indices[0]),
        length: combinationHoldBag?.indices?.length,
      });
      return;
    }

    combinationHoldBag?.indices?.forEach((indice) => {
      holdBags.push(newItem(indice));
    });
  });

  return {
    handBags,
    holdBags,
  };
};

export const getDimensionsAndWeight = (height, width, length, weight) =>
  `${length} x ${width} x ${height}cm, ${weight}kg`;

export const formatPrice = (price) => {
  const [firstPrice, secondPrice] =
    Number.parseFloat(price).toFixed(2)?.split(".") || [];
  return [firstPrice, secondPrice];
};

export const addPercentageToPrice = (price, percentage = 10) => {
  return Number(price + (price * percentage) / 100).toFixed(2);
};

export const isPhoneNumber = (phone) => {
  if (!phone || phone === "+") return false;
  return true;
};

export const getItemFromLocalStorage = (itemKey) => {
  if (!itemKey) return null;

  const itemAsString = localStorage.getItem(itemKey) || "{}";
  return itemAsString ? JSON.parse(itemAsString) : {};
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const dateFromFormattedToMoment = (date) => {
  if (!date) return date;

  return `${date.split("/")[1]}/${date.split("/")[0]}/${date.split("/")[2]}`;
};

export const calculateTimeDifference = (startTime, endTime) => {  
  // Parse the time strings into Date objects
  const start = new Date(`2023-01-01 ${startTime}`);
  const end = new Date(`2023-01-01 ${endTime}`);

  // Calculate the time difference in milliseconds
  const timeDifferenceMs = end - start;

  // Convert milliseconds to hours and minutes
  const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
  const minutes = Math.floor(
    (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60)
  );

  // Format the result as "Xh Ym"
  if (hours >= 0 && minutes >= 0) {
    const result = `${hours}h ${minutes}m`;
 
    return result;
  }

  return "";
};

export const convertSecondsToHoursMinutes = (minutes) => { 

    var hours = Math.floor(minutes / 60);          
    var minutesFinal = minutes % 60;
   return { hours: hours, minutes: minutesFinal };
};
